body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  background-color: #242424 !important;
}

.form-control, .form-control:focus {
  background-color: #333 !important;
  border-color: #444 !important;
}

.form-control::placeholder {
  color: #666 !important;
}

.character-avatar-container {
  width: 120px;
  height: 120px;
  position: relative;
  transition: transform 0.2s;
}

.character-avatar-container:hover {
  transform: scale(1.05);
}

.character-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #444;
}

.character-avatar-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #fff;
  text-transform: uppercase;
}

.character-card {
  height: 100%;
  transition: transform 0.2s;
}

.character-card:hover {
  transform: scale(1.02);
}

.character-card .card-body {
  padding: 1rem;
}

.chat-container {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
}

.chat-header {
  padding: 1rem;
  background-color: #2f3136;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #444;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: #36393f;
}

.message {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.message-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-content {
  flex: 1;
}

.message-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.message-author {
  font-weight: 500;
  color: #fff;
}

.message-time {
  font-size: 0.75rem;
  color: #72767d;
}

.message-text {
  color: #dcddde;
  line-height: 1.4;
}

.chat-input {
  padding: 1rem;
  background-color: #40444b;
  display: flex;
  gap: 1rem;
}

.chat-input .form-control {
  background-color: #40444b;
  border: none;
  color: #dcddde;
}

.chat-input .form-control:focus {
  box-shadow: none;
  background-color: #40444b;
}

.character-avatar-small {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.character-avatar-small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  background-color: #7289da;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 12px 0;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #72767d;
  border-radius: 50%;
  animation: bounce 1.3s linear infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.15s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

.chat-input .form-control:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.emphasized-text {
  font-style: italic;
  opacity: 1;
  color: #b9bbbe;
}

.message-actions {
  opacity: 0;
  transition: opacity 0.2s;
  margin-left: auto;
}

.message:hover .message-actions {
  opacity: 1;
}

.message-actions .btn-link {
  padding: 0;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  text-decoration: none;
}

.edited-indicator {
  font-size: 0.75rem;
  color: #72767d;
  margin-left: 0.5rem;
}

.edit-message-form {
  margin-top: 0.5rem;
}

.edit-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.modal .message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.modal .message-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-body > .message {
  margin-bottom: 0;
  flex-wrap: wrap;
}
.modal-body > .message > .message-meta {
  width: 100%;
  margin-bottom: 0;
}

.modal-body > .message > .message-input-container {
  width: 100%;
}

.modal .avatar-placeholder {
  width: 100%;
  height: 100%;
  background-color: #7289da;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.modal .message-author {
  font-weight: 500;
  color: #bebebe;
}

.modal .message-meta {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.75rem;
}

.modal .message-input-container {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.modal .form-control {
  flex-grow: 1;
}

.hero-section {
  background-color: #2f3136;
  padding: 80px 0;
  margin-bottom: 40px;
}

.hero-section h1 {
  color: #ffffff;
  margin-bottom: 20px;
}

.hero-section .lead {
  color: #dcddde;
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
}

.feature-icon {
  color: #7289da;
  margin-bottom: 20px;
}

.cta-section {
  background-color: #2f3136;
  margin-top: 40px;
}

.cta-section h2 {
  color: #ffffff;
}

.message-preview {
  background-color: #2f3136;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 0.5rem;
  color: #dcddde;
  max-height: 200px;
  overflow-y: auto;
  word-break: break-word;
}

.badge {
  transition: all 0.2s;
}

.badge:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.form-select {
  background-color: #333 !important;
  color: #fff !important;
  border-color: #444 !important;
}

.form-select option {
  background-color: #333;
  color: #fff;
}

.pagination {
  --bs-pagination-bg: #242424;
  --bs-pagination-color: #fff;
  --bs-pagination-border-color: #444;
  --bs-pagination-hover-bg: #333;
  --bs-pagination-hover-color: #fff;
  --bs-pagination-focus-bg: #333;
  --bs-pagination-focus-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-bg: #242424;
  --bs-pagination-disabled-color: #666;
}

.character-card .text-muted {
  font-size: 0.85rem;
}

.character-card .chat-count {
  color: #6c757d;
  font-size: 0.8rem;
}

.character-card:hover .text-muted {
  color: #a0a0a0 !important;
}

@media (max-width: 576px) {
  .character-avatar-container {
    width: 80px;
    height: 80px;
  }
  
  .character-card .card-title {
    font-size: 0.9rem !important;
  }
  
  .character-card .card-subtitle,
  .character-card .text-muted {
    font-size: 0.8rem !important;
  }
  
  .badge {
    font-size: 0.7rem;
  }
}

.pagination {
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.25rem;
}

.pagination .page-item .page-link {
  padding: 0.375rem 0.75rem;
}

@media (max-width: 576px) {
  .pagination .page-item .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
}

/* Improve touch targets on mobile */
@media (max-width: 768px) {
  .form-select,
  .btn {
    min-height: 42px;
  }
  
  .badge {
    padding: 0.5em 0.7em;
  }
}

/* Ensure cards have consistent height */
.character-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.character-card .card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Prevent text overflow */
.text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Improve spacing on mobile */
@media (max-width: 576px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .g-3 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
}
